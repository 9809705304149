<template>

  <div>
    <h6>Selecciona un cliente</h6>
    <b-form-group>
      <v-select
        v-model="clientSelected"
        label="title"
        :options="clients"
      />
    </b-form-group>

    <div v-if="clientSelectedData">
      <h6>{{ clientSelectedData.id }} {{ clientSelectedData.nombre }}</h6>
      <p>{{ clientSelectedData.dir_literal }}</p>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        @click="saveSelectClient"
      >
        Continuar
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useShopRemoteData } from './useShop'

export default {
  components: {
    // BCard,
    BFormGroup,
    BButton,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      clients,
      fetchClients,
      clientSelected,
      clientSelectedData,
      saveSelectClient,
      checkClientSelected,
      resultClient,
    } = useShopRemoteData()

    fetchClients()
    resultClient()

    return {
      clients,
      clientSelected,
      clientSelectedData,
      saveSelectClient,
      checkClientSelected,
      resultClient,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <div>
    <div
      v-if="!processShop"
      class="text-center"
    >
      <b-row>
        <b-col>
          <b-card
            title="Bienvenido a Bimbo Shop"
            class="text-center"
          >
            <b-img
              alt="shoping card"
              fluid
              class="card-img-top"
              :src="require('@/assets/images/ecommerce.svg')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mt-4"
              block
              @click="initializeShop"
            >
              Iniciar un Nuevo Pedido
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              block
              :to="{ path: '/bimbo/shop-list' }"
            >
              Lista de pedidos
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <template v-else>
      <div class="volver-container">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          class="volver"
          @click="ProcessVolver"
        >
          Volver
        </b-button>
      </div>

      <shop-client v-if="processShop === 1" />
      <shop-cart v-else-if="processShop === 2" />
    </template>
  </div>
</template>

<script>
import {
  BButton,
  BImg,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onMounted } from '@vue/composition-api'
import ShopClient from './ShopClient.vue'
import ShopCart from './ShopCart.vue'
import { useCartItems, useShopUi, useShopRemoteData } from './useShop'

export default {
  components: {
    BButton,
    BImg,
    BRow,
    BCol,
    BCard,
    ShopClient,
    ShopCart,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      initializeShop,
      checkResetCart,
    } = useCartItems()

    const {
      clientSelectedData,
    } = useShopRemoteData()

    onMounted(checkResetCart)

    const {
      processShop,
      ProcessVolver,
    } = useShopUi()

    return {
      processShop,
      ProcessVolver,
      initializeShop,
      clientSelectedData,
    }
  },
}
</script>

<style lang="scss" scoped>
.volver-container {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: justify;
  // justify-content: end;
  justify-content: flex-end;
  margin-bottom: 3rem;

  // .volver {
  //   right: 0px;
  //   position: absolute;
  // }
}
</style>
